import * as React from "react"
import PropTypes from "prop-types"
import { Image } from "theme-ui"

const DEFAULT_CDN_URL =
  "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/"
const DEFAULT_CDN_SUFFIX = "svg"

function Flag({ cdnUrl, countryCode, style, svg, ...props }) {
  if (typeof countryCode !== "string") {
    return null
  }

  if (svg) {
    const flagUrl = `${cdnUrl}${countryCode.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`

    return (
      <Image
        {...props}
        src={flagUrl}
        variant="flag"
        sx={{
          ...style,
        }}
      />
    )
  }
}

Flag.propTypes = {
  cdnUrl: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  style: PropTypes.object,
  svg: PropTypes.bool,
}

Flag.defaultProps = {
  cdnUrl: DEFAULT_CDN_URL,
  svg: false,
}

export default Flag
