/** @jsx jsx */
import { useState, useEffect } from "react"
// import firebase from "gatsby-plugin-firebase"
// import incrementViews from "../../lib/increment-views"
import { Box, Flex, jsx } from "theme-ui"

import useFirebase from "../hooks/useFirebase"

import { FiEye } from "react-icons/fi"

const ViewCounter = ({ id, increase }) => {
  // set current views
  const [viewCount, setViewCount] = useState("")
  const firebase = useFirebase()

  // update when clicked
  useEffect(() => {
    if (!firebase) return

    const incrementViews = id => {
      const viewsRef = firebase.database().ref(`views`)
      var idRef = viewsRef.child(id)

      // short version would be:
      // var idRef = firebase.database().ref(`views/${id}`)

      // retrieve current value und atomatically modify (instead of overwrite)
      // Transactions are useful when you want to update a field's value based on its current value, or the value of some other field.
      idRef.transaction(currentViews => {
        if (currentViews === null) currentViews = 0
        return currentViews + 1
      })
    }

    const onViews = newViews => {
      setViewCount(() => newViews.val())
    }

    // increment the view number for the post with id
    increase && incrementViews(id)

    firebase.database().ref("views").child(id).on("value", onViews)

    return () => {
      if (firebase.database()) {
        firebase.database().ref("views").child(id).off("value", onViews)
      }
    }
  }, [id, increase, firebase])

  // show
  return (
    <Flex
      sx={{
        alignItems: "center",
        color: "muted",
        fontSize: 0,
        justifyContent: "flex-end",
      }}
    >
      <FiEye />
      <Box
        sx={{
          marginLeft: 1,
        }}
      >
        {viewCount ? viewCount : `---`}
      </Box>
    </Flex>
  )
}

export default ViewCounter
